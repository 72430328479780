import styled from "styled-components";

const GridItem = styled.div`
  background-repeat:none;
  background-size:cover;
  background-image: ${(props) => (props.bgImage ? `url("${props.bgImage}")` : "none")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "transparent")};
  background-position: ${(props) => (props.bgPosition ? props.bgPosition : "center center")};

  grid-column: ${(props) => (props.lgCol ? props.lgCol : "1 / -1")};
  grid-row: ${(props) => (props.lgRow ? props.lgRow : "1 / -1")};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  {/*z-index: ${(props) => (props.zIndex ? props.zIndex : "1")};*/}

  @media ${(props) => "only screen and (max-width: 992px)"} {
    grid-column: ${(props) => (props.mdCol ? props.mdCol : "1 / -1")};
    grid-row: ${(props) => (props.mdRow ? props.mdRow : "1 / -1")};
  }

  @media ${(props) => "only screen and (max-width: 768px)"} {
    grid-column: ${(props) => (props.smCol ? props.smCol : "1 / -1")};
    grid-row: ${(props) => (props.smRow ? props.smRow : "1 / -1")};
    text-align: left !important;
  }

`;

export default GridItem;
