import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(12, 1fr) 80px;
  grid-template-rows: repeat(12, minmax(8.33333vh,auto) );
  gap: ${(props) => (props.gapDefault ? props.gapDefault : "2px")};
  margin-top:2px;

  /*@media ${(props) => props.theme.breakpoints.m} {*/
  @media ${(props) => "only screen and (max-width: 992px)"} {
    grid-template-columns: 120px repeat(12, 1fr) 120px;
    grid-template-rows: repeat(12, minmax(8.33333vh,auto) );
    gap: ${(props) => (props.gapMedium ? props.gapMedium : "2px")};
  }

  /*@media ${(props) => props.theme.breakpoints.s} {*/
  @media ${(props) => "only screen and (max-width: 768px)"} {
    overflow-x:hidden;
    grid-template-columns: 40px repeat(12, 1fr) 40px;
    grid-template-rows: repeat(12, minmax(8.33333vh,auto));
    //grid-auto-rows: minmax(1fr, auto);
    gap: ${(props) => (props.gapSmall ? props.small : "2px")};
  }
`;

export default Grid;
