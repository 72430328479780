import { createGlobalStyle } from "styled-components";

import Grid from '../images/grid1.svg';
import DNA from '../images/DNAStrand.svg';

export const GlobalStyles = createGlobalStyle`

    //Quasimoda + Space Mono
    @import url("https://use.typekit.net/kax1pbm.css");

    /////////////////////////////
    //RESETS, CSS VARIABLES & UNIVERSAL CLASSES
    /////////////////////////////

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      font-size: 16px;

      --white: #FFFFFF;
      --gray-dark: #343533;
      --gray: #C4BEBB;
      --gray-light: #efefef;
      --green:#46918B;
      --green-light: #BBE5DB;
      --purple: #67205C;
      --yellow: #F9F486;
      --orange: #EFB389;
      --swiper-theme-color: #2A564E !important;
    }

    html, body {
        height: 100%;
    }

    body {
        font-family: quasimoda,-apple-system, BlinkMacSystemFont, 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
 
        font-size:21px;
        font-size: calc(10px + 2vmin);
        line-height:1.4;
        margin: 0;
        overflow-x: hidden;

        background: var(--gray-dark);
        color: var(--white);
    }

    p{margin-bottom:20px;}

    img{max-width:100%;height:auto;}

    a, .pseudo-link{
        cursor:pointer;
        color:var(--blue);
        font-weight:bold;
    }
    a:focus{outline: var(--gray-light);}

    .page{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: center;
    }
    .page-privacy{
        h1,h2,h3,h4,h5,h6{margin-top:2em;}

        font-size:20px;
        line-height:1.4;
    }

    .text-dark{color:var(--gray-dark) !important;}

    .small{font-size:16px;font-family: space-mono, mono;}

    ul{margin-left:1em;}
    ul li {margin-bottom:1em;}
    
    /////////////////////////////
    //HEADER DEFAULTS
    /////////////////////////////

    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
        font-weight:700;
        margin-bottom: 0.5em;
        font-family: space-mono, mono;
    }
    h1{margin-bottom:0;}


    /////////////////////////////
    //UNIVERSAL STYLE/SPACING/ALIGNMENT CLASSES
    /////////////////////////////

    //Gradients
    .BGgrad-o-p-g{
        background: rgb(239,179,137) !important;
        background: linear-gradient(45deg, rgba(239,179,137,1) 0%, rgba(227,166,133,1) 20%, rgba(103,32,92,1) 41%, rgba(70,145,139,1) 56%, rgba(70,145,139,1) 100%) !important;
    }
    .BGgrad-gl-y{
        background: rgb(70,145,139) !important;
        background: linear-gradient(45deg, rgba(70,145,139,1) 0%, rgba(249,244,134,1) 68%) !important;
    }
    .BGgrad-gl-o{
        background: rgb(187,229,219) !important;
        background: linear-gradient(145deg, rgba(187,229,219,1) 0%, rgba(193,223,209,1) 34%, rgba(239,179,137,1) 76%) !important;
    }

    .BGgrad-p-o{
        background: rgb(103,32,92) !important;
        background: linear-gradient(145deg, rgba(103,32,92,1) 0%, rgba(239,179,137,1) 68%) !important;
    }
    
    .BGpatt-grid{
        background: var(--gray-dark) url( ${Grid} ) repeat center center !important;
        background-size: 250px auto !important;
    }
    .BGpatt-DNA{
        background: url( ${DNA} ) repeat-y center center !important;
        background-size: 100% auto !important;
    }

    .typewriter-container{width:50vw;margin:0 auto;}

    .sans-serif{font-family: quasimoda,-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;}
    .serif{font-family: space-mono,mono,-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;}

    .align-top{vertical-align:top;}
    .align-middle{vertical-align:middle;}
    .align-bottom{vertical-align:bottom;}

    .text-center{text-align:center;}
    .text-left{text-align:left;}
    .text-right{text-align:right;}

    .mt-1{margin-top:20px !important;}
    .mt-2{margin-top:40px !important;}
    .mt-3{margin-top:80px !important;}
    .mb-1{margin-bottom:20px !important;}
    .mb-2{margin-bottom:40px !important;}
    .mb-3{margin-bottom:80px !important;}
    .mr-1{margin-right:20px !important;}
    .mr-2{margin-right:40px !important;}
    .mr-3{margin-right:80px !important;}
    .ml-1{margin-left:20px !important;}
    .ml-2{margin-left:40px !important;}
    .ml-3{margin-left:80px !important;}

    .p-10{padding:10px !important;}
    .p-20{padding:20px !important;}
    .pl-20{padding-left:20px !important;}
    .pr-20{padding-right:20px !important;}

    .h-100{height:100vh;}


    /////////////////////////////
    //SPECIFIC USE CLASSES
    /////////////////////////////
    .z-index-999{z-index:999;}

    .hidden{display:none;}


    /////////////////////////////
    //COLOR SPECIFIC STYLING
    /////////////////////////////
    .highlight-green{background: linear-gradient(0deg, var(--green) 40%, transparent 40%);}
    .highlight-yellow{background: linear-gradient(0deg, var(--yellow) 40%, transparent 40%);}
    .highlight-purple{background: linear-gradient(0deg, var(--purple) 40%, transparent 40%);}

    .green{color:var(--green);}
    .yellow{color:var(--yellow);}
    .purple{color:var(--purple);}


    /////////////////////////////
    //BUTTONS
    /////////////////////////////

    .btn{
        display:inline-block;
        padding: 15px 80px;
        color:var(--white);
        transition: all 0.5s ease;
        cursor:pointer;
        margin:20px 0;
        text-align:center;
        border:none;
        box-shadow: 3px 3px 0px var(--white), 4px 4px 0 var(--blue);
        font-weight:bold;

        &:hover{
            background-color: var(--blue-lighten);
            background-position: right 30px center;
            box-shadow: 4px 5px 0px var(--white), 5px 6px 0 var(--blue-lighten);
        }
    } 
    .btn.no-icon{
        background:var(--blue); !important;
        padding:15px !important;
    }
    .btn:disabled{
        background:var(--gray) !important;
        box-shadow: 1px 1px 0px var(--white), 2px 2px 0 var(--gray);
        color:var(--gray-dark);
    }


    /////////////////////////////
    //FOOTER STYLES
    /////////////////////////////
    .footer{
        padding:20px;
        background:var(--blue);
        color: var(--white) !important;
        font-size:16px;

        a{color: var(--white) !important;}
    }

    

//////////////////////////////////////////////////
//V INCLUDED COMPONENT OVERRIDES V
////////////////////////////////////////////////

    /////////////////////////////
    //THINGS THAT SPIN
    /////////////////////////////
    .spin-me{
        pointer-events: none;
        width:40vw;
        height:auto;
    }

    @media (prefers-reduced-motion: no-preference) {
        .spin-me {animation: spin-me-spin infinite 30s linear;}
    }
    @keyframes spin-me-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }

    .player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
    position: absolute;
    top: 0;
    left: 0;
    }

//////////////////////////////////////////////////
// ^ END OF INCLUDED COMPONENT OVERRIDES ^
////////////////////////////////////////////////


//////////////////////////////////////////////////
// V RESPONSIVE RULES V
////////////////////////////////////////////////

    /////////////////////////////
    //MOBILE DEFAULTS
    /////////////////////////////
    h1,h2,h3{hyphens: manual;}
    h1,.h1{font-size:52px;line-height:58px;}
    h2,.h2{font-size:46px;line-height:52px;min-height:2em;display:block;}
    h3,.h3{font-size:30px;line-height:36px;}
    h4,.h4{font-size:32px;line-height:36px;}
    h5,.h5{font-size:28px;line-height:34px;}
    h6,.h6{font-size:24px;line-height:30px;}
    .h-xl{font-size:72px;line-height:80px;}
    .h-xxl{font-size:100px;line-height:80px;}

    .text-sm{font-size:18px;}
    .text-lg{font-size:24px;}
    .text-xl{font-size:28px;}
    .text-xxl{font-size:40px;}

    .hide-mobile{display:none;}

    .collapse-grid{grid-template-rows: repeat(12, auto) !important;}
    //.collapse-grid{grid-template-rows: repeat(12, minmax(2.5vh,auto) ) !important;}

    /////////////////////////////
    //'MEDIUM' OVERRIDES
    /////////////////////////////
    @media only screen and (min-width: 768px) {
        h1,h2,h3{hyphens: none;}
        h1,.h1{font-size:90px;line-height:90px;}
        h2,.h2{font-size:72px;line-height:84px;}
        h3,.h3{font-size:48px;line-height:52px;}
        h4,.h4{font-size:42px;line-height:54px;}
        h5,.h5{font-size:36px;line-height:43px;}
        h6,.h6{font-size:28px;line-height:36px;}
        .h-xl{font-size:120px;line-height:80px;}
        .h-xxl{font-size:200px;line-height:160px;}

        .text-sm{font-size:18px;}
        .text-lg{font-size:28px;}
        .text-xl{font-size:36px;}
        .text-xxl{font-size:48px;}

        .hide-mobile{display:block;}

        /*.spin-me{max-width:150px;height:auto;}*/

        .text-lg-left{text-align:left;}
        .text-lg-right{text-align:right;}

        .timeline-card{
            width:75vw;
            margin-right:20px;

            .timeline-month{margin-bottom:10px;}
        }
        .timeline-card.w-100{width:100vw;}
        
    }

    /////////////////////////////
    //'LARGE' OVERRIDES
    /////////////////////////////
    @media only screen and (min-width: 992px) {

        .collapse-grid{grid-template-rows: repeat(12, minmax(8.33333333vh,auto) ) !important;}

        h3,.h3{font-size:52px;line-height:56px;}

        .timeline-card{
            width:600px;
        }

        .accordion{
            .accordion__button{
                padding-left:80px;
                padding-right:80px;
            }
        }

    }

//////////////////////////////////////////////////
// ^ END RESPONSIVE RULES ^
////////////////////////////////////////////////

`;
