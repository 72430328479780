import React from 'react';
//import ReactDOM from 'react-dom';
import { ThemeProvider } from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';

import Theme from "./styles/theme";
import { GlobalStyles } from "./styles/globalStyles";

import App from './App';
import { hydrate, render } from 'react-dom';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(
      <ParallaxProvider>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />
          
            <App />
          
        </ThemeProvider>
      </ParallaxProvider>
      , rootElement);
} else {
    render(
      <ParallaxProvider>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />
          
            <App />
          
        </ThemeProvider>
      </ParallaxProvider>
    , rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
