export const Globe = require('./globe.svg').default;
export const Grid1 = require('./grid1.svg').default;
export const HealthBar = require('./healthbar.svg').default;
export const Heart = require('./heart.svg').default;
export const Magnifying = require('./magnifying.svg').default;
export const Person = require('./person.svg').default;
export const Skeleton = require('./skeleton.svg').default;

export const HeartMeter = require('./heartmeter.svg').default;
export const DNAStrand = require('./DNAStrand.svg').default;
export const Hand = require('./hand.svg').default;
export const Connections = require('./connections.svg').default;