//Imported Library Components
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Parallax } from 'react-scroll-parallax';
import Typist from 'react-text-typist';

import ReactPlayer from 'react-player/file'
import IntroVideo from './video/Roche_60s.mp4';

import * as AllImages from './images';
import Vignette1 from './pdfs/Innovator-Vignette.pdf';
import Vignette2 from './pdfs/HIM-Vignette.pdf';

//Custom-built Components
import Image from './components/Layout/Image';
import Grid from './components/Layout/Grid';
import GridItem from './components/Layout/GridItem';
import ScrollToTop from './components/Layout/ScrollToTop';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.svg';

class App extends Component {

  //Constructor
  constructor (props) {
    super(props)
    this.state = {
      width: window.innerWidth,
    }
  }

  // Adds a listener to the the window size (for enable/disabling parallax on mobile)
  componentDidMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}
	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	// dynamically detect window
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};
  //disable Parallax on small screens
  disableParallax(windowWidth) {
		//return windowWidth <= 992;
    return windowWidth <= 768;
	}


  //////////////////////////////
  ////////////BEGIN RENDER METHOD
  render() {
  return (
    
    <div className="App">
    
      <Helmet>
          <meta charSet="utf-8" />
          <title>Data Sharing Matters</title>
          <link rel="canonical" href="https://datasharingcounts.com/" />
          <meta name="description" content="Health data can provide insights and information that can be used to improve the health of individuals, families and even communities. Health data can also help us develop new technologies that can help us understand and treat diseases or provide better health." />
          {/* SET ANY ADDITIONAL METADATA HERE AND RERUN BUILD! */}
      </Helmet>

      <Router>
      {/*basename='/healthdata/'*/}
        <ScrollToTop />
        <Switch>
          <Route path="/privacy">
            <Privacy disableParallax={this.disableParallax} appWidth={this.state.width}/>
          </Route>
          <Route path="/">
            <Home disableParallax={this.disableParallax} appWidth={this.state.width}/>
          </Route>
        </Switch>
      </Router>

    </div>
  );
  }
}

function Home(props) {
  return (
    <div className="page-home">

    <header>
    <Grid as="section">
      <GridItem
        as="figure"
        alt="Block of Green Colour"
        bgColor="var(--green)"
        lgCol="3 / -1" lgRow="1 / -3"
        mdCol="2 / -1" mdRow="1 / -3"
        smCol="1 / -2" smRow="2 / -2"
      >
      </GridItem>
      
      <GridItem
        as="figure"
        alt="Block of Yellow Colour"
        bgColor="var(--yellow)"
        lgCol="1 / 3" lgRow="1 / -3"
        mdCol="1 / 2" mdRow="1 / -3"
        smCol="1 / -2" smRow="1"
      >
      </GridItem>

      <GridItem
        as="figure"
        alt="Block of Light Green Colour"
        bgColor="var(--green-light)"
        className="BGgrad-gl-o"
        lgCol="1 / -2" lgRow="-3 / -1"
        mdCol="1 / -2" mdRow="-3 / -1"
        smCol="-2 / -1" smRow="2 / -2"
      >
      </GridItem>

      <GridItem
        as="figure"
        alt="Block of Purple Colour"
        bgColor="var(--purple)"
        lgCol="-2 / -1" lgRow="-3 / -1"
        mdCol="-2 / -1" mdRow="-3 / -1"
        smCol="1 / -1" smRow="-2"
      >
      </GridItem>

      <GridItem
        lgCol="8 / -4" lgRow="1 / -3"
        mdCol="7 / -4" mdRow="3 / -3"
        smCol="4 / -4" smRow="6 / -2"
        textAlign="left"
      >
        <div className="page">
          <Parallax y={[-50, 25]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
            <h1>Health Data <br/><Typist sentences={['Counts']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h1>
          </Parallax>
        </div>
      </GridItem>

      <GridItem
        lgCol="5 / 9" lgRow="1 / 8"
        mdCol="3 / 8" mdRow="1 / 8"
        smCol="4 / -4" smRow="4 / -2"
      >
        <Parallax y={[-75, 100]} x={[25,-50]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <Image
          src={AllImages.Globe} 
          alt="Globe diagram"
          className="spin-me"
        />
        </Parallax>
      </GridItem>

      <GridItem
          as="figure"
          alt="Dark Gray grid pattern"
          bgColor="var(--gray-dark)"
          className="BGpatt-grid"
          lgCol="-2 / -1" lgRow="1 / 4"
          mdCol="-2 / -1" mdRow="1 / 3"
          smCol="-2 / -1" smRow="1 / 2"
        >
      </GridItem>

    </Grid>
    </header>

    {/*/////////////////////////////
    //////////// Video
    //////////////////////////////*/}
    <Grid className="collapse-grid">
      <GridItem
        as="figure"
        alt="Block of Light Green Colour"
        bgColor="var(--green-light)"
        lgCol="1 / -2" lgRow="1 / -1"
        mdCol="1 / -2" mdRow="1 / -1"
        smCol="1 / -1" smRow="1 / -2"
      >
      </GridItem>
      <GridItem
        as="figure"
        alt="Block of Orange Colour"
        bgColor="var(--orange)"
        className="BGgrad-gl-o"
        lgCol="-2 / -1" lgRow="1 / -1"
        mdCol="-2 / -1" mdRow="1 / -1"
        smCol="1 / -1" smRow="-2 / -1"
      >
      </GridItem>

      <GridItem
        lgCol="-6 / -2" lgRow="4 / -4"
        mdCol="-8 / -1" mdRow="2 / -2"
        smCol="-8 / -1" smRow="2 / -2"
      >
        <Parallax y={[100, -50]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <Image
          src={AllImages.Connections} 
          alt="Connections illustrated diagram"
          className="spin-me"
        />
        </Parallax>
      </GridItem>
      
     <GridItem
        as="figure"
        alt="Introductory video"
        lgCol="3 / -3" lgRow="3 / -2"
        mdCol="1 / -2" mdRow="2 / -2"
        smCol="1 / -1" smRow="1 / -1"
      >
        <div className='player-wrapper'>
        <ReactPlayer className="react-player" url={IntroVideo} controls="true" width="100%" height="100%"/>
        </div>
      </GridItem>
    </Grid>

    {/*/////////////////////////////
    //////////// Question #1
    //////////////////////////////*/}

    <Grid as="section">
      
    <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--yellow)"
          lgCol="1 / 2" lgRow="1 / -2"
          mdCol="1 / 2" mdRow="1 / -2"
          smCol="1 / 2" smRow="1 / -2"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Light Green Colour"
          bgColor="var(--green-light)"
          className="BGpatt-grid"
          lgCol="1 / 5" lgRow="-2 / -1"
          mdCol="1 / 5" mdRow="-2 / -1"
          smCol="1 / 5" smRow="-2 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--yellow)"
          className="BGgrad-gl-y"
          lgCol="5 / -1" lgRow="-2 / -1"
          mdCol="5 / -1" mdRow="-2 / -1"
          smCol="5 / -1" smRow="-2 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Orange Colour"
          bgColor="var(--orange)"
          lgCol="2 / -1" lgRow="1 / -2"
          mdCol="2 / -1" mdRow="1 / -2"
          smCol="2 / -1" smRow="1 / -2"
        >
      </GridItem>
      
      <GridItem
        lgCol="11 / -3" lgRow="-7 / -1"
        mdCol="13 / -1" mdRow="-5 / -1"
        smCol="3 / 8" smRow="2 / 4"
      >
       <Parallax x={["-250px","0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.Person} alt="Person icon illustration"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="4 / -6" lgRow="2 / -3"
        mdCol="3 / -3" mdRow="2 / -3"
        smCol="3 / -2" smRow="4 / -2"
      >
        <div className="page">
          <Parallax x={["75px", "-10px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
            <h3>What is <br/>personal health <br/><Typist sentences={['information?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>

          <Parallax x={["25px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <div>
          <p>Personal health information (PHI) is “identifying information” about an individual's health or health care history.</p>
          <p>This can include quite a lot of information, such as information about your health condition(s), treatment, health care number, drugs that you may be taking, and so much more.</p>
          <p>
            <a
            className="App-link small"
            href="https://www.gov.nl.ca/hcs/files/phia-phia-faqs-feb-2011.pdf"
            target="_blank"
            rel="noopener noreferrer">
              Privacy laws in Canada define and aim to protect PHI
            </a>
          </p>
          <br/><br/>
          </div>
          </Parallax>

        </div>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #2
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of White Colour"
          bgColor="var(--white)"
          className="BGgrad-p-o"
          lgCol="-3 / -1" lgRow="1 / -1"
          mdCol="-2 / -1" mdRow="1 / -1"
          smCol="-2 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Purple Colour"
          bgColor="var(--purple)"
          lgCol="1 / -3" lgRow="1 / -1"
          mdCol="1 / -2" mdRow="1 / -1"
          smCol="1 / -2" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="5 / -6" lgRow="1 / -3"
        mdCol="6 / -5" mdRow="1 / -3"
        smCol="2 / -4" smRow="4 / 10"
      >
        <div className="page">
          <Parallax x={["-50px", "50px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
            <h3>What is <br/>health <Typist sentences={['data?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>
          <Parallax x={["-75px", "100px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
            <p>Health data can include PHI, however it can also include information that cannot be linked to a person. This type of data is often referred to as anonymized.</p>
          </Parallax>
        </div>
      </GridItem>

      <GridItem
        lgCol="1 / 4" lgRow="1 / 8"
        mdCol="1 / 4" mdRow="1 / 7"
        smCol="6 / -1" smRow="3 / 5"
      >
        <Parallax y={[-25, 100]} x={[25,-15]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <Image
          src={AllImages.Magnifying} 
          alt="Magnifying glass illustration"
          className="spin-me"
        />
        </Parallax>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #3
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Light Green Colour"
          bgColor="var(--green-light)"
          lgCol="2 / -1" lgRow="1 / -2"
          mdCol="2 / -1" mdRow="1 / -2"
          smCol="2 / -1" smRow="1 / -2"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of White Colour"
          bgColor="var(--white)"
          lgCol="1 / 2" lgRow="1 / -2"
          mdCol="1 / 2" mdRow="1 / -2"
          smCol="1 / 2" smRow="1 / -2"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of White Colour"
          bgColor="var(--white)"
          lgCol="-2 / -1" lgRow="-2 / -1"
          mdCol="-2 / -1" mdRow="-2 / -1"
          smCol="-2 / -1" smRow="-2 / -1"
        >
      </GridItem>
      <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--yellow)"
          lgCol="1 / -2" lgRow="-2 / -1"
          mdCol="1 / -2" mdRow="-2 / -1"
          smCol="1 / -2" smRow="-2 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="4 / 9" lgRow="1 / -1"
        mdCol="3 / 9" mdRow="1 / -2"
        smCol="4 / -3" smRow="6 / -3"
      >
        <div className="page text-dark">
          <Parallax x={["100px", "-50px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <h3>What can <br/>it <Typist sentences={['do?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>

          <Parallax x={["50px", "-25px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <p>Health data can provide insights and information that can be used to improve the health of individuals, families and even communities. Health data can also help us develop new technologies that can help us understand and treat diseases or provide better health.</p>
          </Parallax>

        </div>
      </GridItem>

      <GridItem
        lgCol="9 / -1" lgRow="3 / -4"
        mdCol="9 / -1" mdRow="3 / -4"
        smCol="2 / -1" smRow="2 / 5"
      >
        <Parallax y={[-25, 15]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <Image
          src={AllImages.HeartMeter} 
          alt="Illustration of heart with heart rate monitor"
        />
        </Parallax>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #4
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Orange Colour"
          bgColor="var(--orange)"
          className="BGgrad-p-o"
          lgCol="1 / 6" lgRow="1 / -1"
          mdCol="1 / -4" mdRow="1 / -1"
          smCol="1 / -4" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Orange Colour"
          bgColor="var(--orange)"
          className="BGgrad-p-o"
          lgCol="6 / -1" lgRow="1 / -1"
          mdCol="-4 / -1" mdRow="1 / -1"
          smCol="-4 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        as="figure"
        alt="Illustration of DNA strand"
        lgCol="5 / -9" lgRow="1 / -1"
        mdCol="-4 / -1" mdRow="1 / -1"
        smCol="-4 / -1" smRow="1 / -1"
        className="BGpatt-DNA"
      >
      </GridItem>

      <GridItem
        lgCol="2 / 6" lgRow="1 / -1"
        mdCol="2 / -6" mdRow="2 / 6"
        smCol="2 / -6" smRow="2 / 5"
      >
        <div className="page">
          <Parallax y={["-75px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <h3>Why should our health data be <br/><Typist sentences={['shared?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>
        </div>
      </GridItem>

      <GridItem
        lgCol="8 / -2" lgRow="1 / -1"
        mdCol="2 / -6" mdRow="6 / 12"
        smCol="2 / -6" smRow="5 / 11"
      >
        <div className="page">
          <Parallax y={["-75px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <p>Health data can tell us new information about our own health, how to best treat diseases, and can even give us information on how to keep our communities healthy. If we use health data in a smart and responsible way, the insights we develop may help us to prevent disease and keep people from getting sick in the first place. Additionally, these insights can make our healthcare systems more effective and efficient, speed up important scientific research, and even make healthcare services more sustainable for future generations in Canada.</p>
          </Parallax>
        </div>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #5
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Green Colour"
          bgColor="var(--green)"
          lgCol="1 / -1" lgRow="1 / -1"
          mdCol="1 / -1" mdRow="1 / -1"
          smCol="1 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="2 / 7" lgRow="-4 / -1"
        mdCol="1 / 6" mdRow="5 / -1"
        smCol="3 / -3" smRow="3 / 6"
      >
       <Parallax y={[-150,50]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.HealthBar} alt="Illustration of hearts"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="8 / -3" lgRow="1 / -1"
        mdCol="7 / -2" mdRow="3 / -1"
        smCol="3 / -3" smRow="6 / -2"
      >
        <div className="page">
          <Parallax y={["-75px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <h3>Who does it get shared with <br/><Typist sentences={['anyway?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>

          <p>Your personal health information is shared with those who are involved in providing your care or others that you choose to share it with.</p>
          <p>You can provide it to anyone you wish. The choice is yours!</p>
        </div>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #6
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Orange Colour"
          bgColor="var(--orange)"
          className="BGgrad-gl-o"
          lgCol="2 / -1" lgRow="1 / -1"
          mdCol="2 / -1" mdRow="1 / -1"
          smCol="2 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Purple Colour"
          bgColor="var(--purple)"
          lgCol="1 / 2" lgRow="1 / -1"
          mdCol="1 / 2" mdRow="1 / -1"
          smCol="1 / 2" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="-8 / -1" lgRow="1 / -1"
        mdCol="-8 / -1" mdRow="1 / -1"
        smCol="2 / -1" smRow="1 / 8"
      >
       <Parallax y={["-150px","250px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.Skeleton} alt="Illustration of skeletal structure"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="3 / -8" lgRow="3 / -1"
        mdCol="3 / -8" mdRow="3 / -1"
        smCol="3 / -3" smRow="6 / -2"
      >
        <div className="page text-dark">
          <Parallax y={["-150px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <h3>How is it <br/><Typist sentences={['protected?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>

          <Parallax y={["-50px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <p>Data is protected in many different ways. For instance, ‘data minimization’ is one way companies are protecting data. It means that they only collect as little information that is needed and no more. (The less they have - the better. Less is more!)</p>
          <p>There are also many other privacy and security practices whereby data is secured.</p>
          </Parallax>
        </div>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// Question #7
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Green Colour"
          bgColor="var(--green)"
          lgCol="5 / -1" lgRow="1 / -1"
          mdCol="5 / -1" mdRow="1 / -1"
          smCol="5 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--green)"
          className="BGgrad-gl-y"
          lgCol="1 / 5" lgRow="1 / -1"
          mdCol="1 / 5" mdRow="1 / -1"
          smCol="1 / 5" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="3 / 7" lgRow="1 / -1"
        mdCol="3 / 7" mdRow="1 / -1"
        smCol="3 / 7" smRow="3 / 5"
      >
       <Parallax y={["-50px","250px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.Heart} alt="Illustration of a heart"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="-8 / -1" lgRow="4 / -4"
        mdCol="-7 / -1" mdRow="4 / -4"
        smCol="8 / -1" smRow="1 / -1"
        className="hide-mobile"
      >
       <Parallax y={[100,-50]} x={[0,75]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.Hand} alt="Illustration of a hand"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="4 / -7" lgRow="1 / -1"
        mdCol="4 / -7" mdRow="1 / -1"
        smCol="6 / -2" smRow="5 / -1"
      >
        <div className="page">
          <Parallax y={["-75px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
          <h3>What can <br/><Typist sentences={['you do?']} loop={true} cursorSmooth={true} typingSpeed={160} deletingSpeed={80} pauseTime={4000}/></h3>
          </Parallax>

          <Parallax x={["50px", "250px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
           <p>You can find out more about how others share your data and how it will be used. Once you know these things you can then choose for yourself and ultimately exercise control over it!</p>
          </Parallax>
        </div>
      </GridItem>

    </Grid>

    {/*/////////////////////////////
    //////////// References
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Purple Colour"
          bgColor="var(--purple)"
          lgCol="1 / -1" lgRow="1 / -2"
          mdCol="1 / -1" mdRow="1 / -2"
          smCol="1 / -1" smRow="1 / -2"
        >
      </GridItem>
      <GridItem
          as="figure"
          alt="Block of Dark Gray Grid Pattern"
          bgColor="var(--gray-dark)"
          className="BGpatt-grid"
          lgCol="5 / -1" lgRow="1 / 2"
          mdCol="5 / -1" mdRow="1 / 2"
          smCol="5 / -1" smRow="1 / 2"
        >
      </GridItem>
      <GridItem
          as="figure"
          alt="Block of Orange Colour"
          bgColor="var(--orange)"
          lgCol="1 / 7" lgRow="-2 / -1"
          mdCol="1 / 7" mdRow="-2 / -1"
          smCol="1 / 7" smRow="-2 / -1"
        >
      </GridItem>
      <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--yellow)"
          lgCol="7 / 12" lgRow="-2 / -1"
          mdCol="7 / 12" mdRow="-2 / -1"
          smCol="7 / 12" smRow="-2 / -1"
        >
      </GridItem>
      <GridItem
          as="figure"
          alt="Block of Green Colour"
          bgColor="var(--green)"
          lgCol="12 / -1" lgRow="-2 / -1"
          mdCol="12 / -1" mdRow="-2 / -1"
          smCol="12 / -1" smRow="-2 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="6 / -5" lgRow="4 / -4"
        mdCol="6 / -5" mdRow="4 / -4"
        smCol="3 / -3" smRow="2 / 6"
      >
        <Parallax y={[0, -5]} x={[0,-125]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <Image
          src={AllImages.Connections} 
          alt="Connections illustration spinning"
          className="spin-me"
        />
        </Parallax>
      </GridItem>

      <GridItem
        lgCol="-8 / -3" lgRow="-9 / -2"
        mdCol="-8 / -2" mdRow="-8 / -2"
        smCol="3 / -3" smRow="6 / -3"
      >
      
      <Parallax y={[-50, 50]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <div className="page small">
          
          <h3>Want to see more?</h3>
          <ul>
            <li>
              <a
              className="App-link"
              href={Vignette1}
              target="_blank"
              rel="noopener noreferrer">
                Innovator Vignette
              </a>
            </li>
            <li>
              <a
              className="App-link"
              href={Vignette2}
              target="_blank"
              rel="noopener noreferrer">
                Health Information Management Vignette
              </a>
            </li>
          </ul>

          <h6 className="mt-2">Have questions?</h6>
          <ul>
            <li>
              Please email your questions to <a
                className="App-link"
                href="mailto:Operations@CPHIN.ca"
                target="_blank"
                rel="noopener noreferrer">operations@CPHIN.ca</a>
            </li>
            <li>
              Read our <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
            <a href='javascript:void(0)' class="ot-sdk-show-settings">Cookie Settings</a>
            </li>
          </ul>

        </div>
      </Parallax>

      </GridItem>

    </Grid>

    </div>

  );
}

function Privacy(props) {
  return (
    
    <div className="page-privacy">

    {/*/////////////////////////////
    //////////// Privacy Policy
    //////////////////////////////*/}

    <Grid as="section">

      <GridItem
          as="figure"
          alt="Block of Green Colour"
          bgColor="var(--green)"
          lgCol="3 / -1" lgRow="1 / -1"
          mdCol="3 / -1" mdRow="1 / -1"
          smCol="3 / -1" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
          as="figure"
          alt="Block of Yellow Colour"
          bgColor="var(--green)"
          className="BGgrad-gl-y"
          lgCol="1 / 3" lgRow="1 / -1"
          mdCol="1 / 3" mdRow="1 / -1"
          smCol="1 / 3" smRow="1 / -1"
        >
      </GridItem>

      <GridItem
        lgCol="1 / 5" lgRow="1 / -1"
        mdCol="1 / 5" mdRow="1 / -1"
        smCol="1 / 5" smRow="2 / 4"
      >
       <Parallax y={["50px","0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
        <img src={AllImages.Heart} alt="Illustration of a heart"/>
       </Parallax>
      </GridItem>

      <GridItem
        lgCol="5 / -3" lgRow="2 / -1"
        mdCol="5 / -2" mdRow="2 / -1"
        smCol="4 / -2" smRow="5 / -1"
      >
        <div className="page">
          <Parallax y={["0px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
            <p><Link to="/" className="small text-dark">Return to home page</Link></p>
          </Parallax>

          <Parallax y={["0px", "0px"]} tagOuter="figure" disabled={props.disableParallax(props.appWidth)}>
           <h2 className="mt-1">Roche Canada Online Privacy Notice</h2>
           
           <h5>General Privacy Statement</h5>
           <p>At Hoffmann-La Roche Limited (“Roche”) we are committed to protecting your personal information. This Privacy Notice outlines the types of personal information Roche may collect; the means by which Roche may collect, use, or share your personal information; steps Roche takes to protect your personal information; and choices you are provided with respect to the use of your personal information.</p>
           <p>For purposes of this Privacy Notice, “Personal Data” is any information by which you can be individually identified both directly and indirectly, including, but not limited to, your name, address, e-mail address, and telephone number.</p>

           <h5>How and Why We Process Your Personal Data</h5>
           <p>We may request Personal Data about you. Examples of Personal Data that we may collect that directly identifies you includes your name, contact information, email address, and other information in combination with these identifiers. On our websites, we also may collect certain Personal Data information that does not directly identify you by name, but could be used to identify that a specific computer or device has accessed our website.</p>
           <p>We process your Personal Data for many reasons, including:</p>
           <ul>
            <li>To respond to your requests or inquiries;</li>
            <li>To complete a transaction;</li>
            <li>To track and monitor adverse events and other activities related to pharmacovigilance;</li>
            <li>To meet legal, regulatory and security processing requirements;</li>
            <li>To personalize your experience on our website(s);</li>
            <li>To perform website analytics and measure website performance; and</li>
            <li>To maintain our website(s), including for security purposes.</li>
           </ul>

           <h5>More Information on our Processing Activities</h5>
           <p><strong>Responding to Requests or Inquiries:</strong> Personal data may be submitted to us for various purposes, including through direct interactions, via our website(s) and other online resources. For example, you may submit a Medical Information request, inquire about a product, or subscribe to one of our mailing lists. In these instances, we may use the information that you provide to us to take the steps necessary to respond to your request. Depending on your request, we may collect your contact information (such as your name, mailing address, telephone number, job title), your interests and preferences (such as products or areas of interest), and any other information you provide to us. If reporting is required, we may process your data to comply with our legal obligations.</p>
           <p><strong>Completing Transactions:</strong> Your Personal Data may be collected to fulfill services that you have requested and to understand your interests and preferences. In these instances, we may collect your contact information as well as a history of your previous transactions with us (such as order history, customer account information). If you are purchasing or ordering a product or service from us, we use this information to perform our agreement with you.</p>
           <p><strong>Personalizing Your Experience:</strong> We may collect certain information about you, your preferences, and how you have interacted with us in the past in order to understand your interest in our products and services so that we can best serve you. This may include information about your contact and product preferences, languages, marketing preferences, and demographic data. In cases where we collect this information automatically, we collect and process this information for our legitimate business interests. In other cases, we will collect and process this information pursuant to your consent.</p>
           <p><strong>Website Analytics and Tracking:</strong> Where permitted by law, we may combine Personal Data you provide with other information you’ve provided to Roche through our websites. Where permitted by law, we may also combine Personal Data collected through our websites and online resources with Roche’s offline records and information provided to us by third parties. More information about this can be found in the section below “Cookies and Other Web Trackers”.</p>
           <p><strong>Pharmacovigilance and Medical Inquiries:</strong> Any personal data provided to Roche related to adverse events or other activities related to pharmacovigilance will be used solely for these purposes. This information is very important for public health and will be used for the detection, assessment, understanding and prevention of adverse effects or any other medicine-related problem. We collect and process your data for these purposes in accordance with specific GVP (Pharmacovigilance) legislation and in order to comply with our legal obligations. Any personal data provided to Roche related to a medical inquiry may be used to answer the inquiry, follow up on such requests and maintain the information in a Medical Information database for reference. Where required by law (such as for pharmacovigilance, we may also be required to report the data to regulatory authorities. Your data will not be used for any other purpose.</p>

           <h5>No Third-Party Direct Marketing Use</h5>
           <p>We will not sell or otherwise transfer the personally identifiable information you provide to us to any third parties for their own direct marketing use unless we provide clear notice to you and obtain your explicit consent for your data to be shared in this manner.</p>

           <h5>Recipients of your Personal Data</h5>
           <p>We may share your Personal Data with Roche’s affiliates around the world. Our Roche affiliates will use your Personal Data for the same purposes as we do. A list of Roche’s affiliates is available in the current annual report, which can be found in the Investors section of www.roche.com.</p>
           <p>We may also share your Personal Data with outside third parties, including our data processors, for the following purposes:</p>
           <ul>
           <li>To help fulfill Roche business transactions;</li>
           <li>To conduct technical maintenance of our websites and other web platforms;</li>
           <li>To facilitate a merger, consolidation, transfer of control or other corporate reorganization in which Roche participates, or pursuant to a financial arrangement undertaken by Roche;</li>
           <li>To respond to appropriate requests of legitimate government authorities, or where required by applicable laws, court orders, or government regulations; and</li>
           <li>Where needed for corporate audits or to investigate or respond to a complaint or security threat</li>
           </ul>

           <h5>International Transfers of Your Personal Data</h5>
           <p>Any Personal Data you provide to us may be transferred to or stored in a geographic region which imposes different privacy obligations than your country of origin. This may mean that your Personal Data may be sent to a country with less restrictive data protection laws than your own. Any such transfer will be conducted in compliance with applicable law and Personal Data may be subject to the local laws of the jurisdictions within which it is collected, used, disclosed and/or stored, and may be accessed by governmental and law enforcement authorities in those jurisdictions.</p>

           <h5>Retention / Storage Period of Your Personal Data</h5>
           <p>The length of time in which we will store your Personal Data will differ depending on the purpose for which we have collected and are processing your data. In most cases, we will keep the data for three (3) years following our last interaction with you. We may, however, maintain your data for a longer period of time if we are required by law to maintain your data. For example, reports related to pharmacovigilance are kept for a minimum of 10 years after the withdrawal of the product in the last country where the product is marketed.</p>

           <h5>Our Websites</h5>
           <p>We use information to secure our websites, network systems, and other assets. This may include information concerning your IP Address, geographic location, resources you have accessed, and similar information. We collect this information automatically, for our legitimate business interests. Several places on our website require Personal Data if you choose to use them, including surveys, registration, and content sharing features (i.e., “E-mail to a Friend” links). Roche and its business partners collect this information about you only if you voluntarily provide it to us. Please be aware that certain features of this site may not be available to you if you elect not to provide certain Personal Data. Any Personal Data you provide to us will be used in accordance with this Privacy Notice.</p>

           <h5>Cookies and Other Web Trackers</h5>
           <p>Our websites use technology such as cookies, web beacons and other tracking technologies that enable us to deliver content that is tailored to your interests and preferences based on your past activity on the site. Please see our Cookie Policy for more information.</p>

           <h5>Websites We Do Not Own or Control</h5>
           <p>Our websites also may contain links to third-party websites. We do not endorse and are not responsible for the content of third-party websites or resources, and our privacy notice does not apply to any sites that are not affiliated with Roche, even if you access them via a link on our site. You should review the privacy policies of any third-party site before providing any information.</p>

           <h5>Minors</h5>
           <p>We do not knowingly collect any Personal Data from anyone under the age of 16 without the prior, verifiable consent of a legal representative. Such legal representative may have the right, upon request, to view the information provided by the child and require that it be deleted. Moreover, all minors should seek their parent’s or guardian’s (legal representative) permission prior to using or disclosing any Personal Data on our website or online resource. This website is not designed or intended for use by children under the age of 16.</p>

           <h5>Data Security</h5>
           <p>Roche uses technology and security precautions, rules and other procedures to protect your personal information from unauthorized access, improper use, disclosure, loss or destruction. To ensure the confidentiality of your information, Roche uses also industry standard firewalls and password protection. It is, however, your personal responsibility to ensure that the computer you are using is adequately secured and protected against malicious software, such as trojans, computer viruses and worm programs. You are aware of the fact that without adequate security measures (e.g. secure web browser configuration, up-to-date antivirus software, personal firewall software, no usage of software from dubious sources) there is a risk that the data and passwords you use to protect access to your data, could be disclosed to unauthorized third parties.</p>

           <h5>Access</h5>
           <p>You have a right to request access to your Personal Data and to request a correction to it if you believe it is inaccurate. In the event that you believe that your Personal Data is not accurate or you wish access to your Personal Data, you may make a request using the contact information provided below.</p>
           <p>We endeavor to provide timely access to your Personal Data. However, we may require you to verify your identity to our satisfaction prior to doing so. Further, there may be circumstances where access cannot be granted. For example, we will not grant access where doing so would lead to the disclosure of Personal Data of another individual and that individual refuses to provide consent to the disclosure, or where the information is subject to privilege or other legal restrictions. In such cases you will be notified of the reason why it is not possible to grant access to your Personal Data.</p>

           <h5>Updates to This Privacy Notice</h5>
           <p>From time to time, we may revise this Privacy Notice. Any such changes to this Privacy Notice will be reflected on this page. Roche recommends that you review this Privacy Notice regularly for any changes. The date on which this notice was last revised is located at the top of this notice.</p>

           <h5>How to Contact Roche</h5>
           <p>For questions or if you wish Roche to amend or delete your profile, please contact us in writing (by post), as follows:</p>

           <h6>Pharmaceuticals Division</h6>
           <p>Privacy Officer<br/>
           7070 Mississauga Road,<br/>
           Mississauga, Ontario<br/>
           L5N 5M8<br/>
           Tel.: 1-800-561-1759<br/></p>

           <h6>Diagnostics Division</h6>
           <p>Privacy Officer<br/>
           201 Armand-Frappier Blvd<br/>
           Laval, Quebec<br/>
           H7V 4A2<br/>
           Tel.: 1-800-361-2070<br/></p>
           
           <br/><br/>

           <div className="small">
           <p>This website contains materials intended only for residents of Canada, and may contain product details or information not available or valid outside of Canada. We accept no responsibility for users outside of Canada accessing portions of this website which may be invalid or non-compliant with the laws, regulations or standards of the user’s home country.</p>
           
           <p>This website is neither intended nor designed to record or report adverse event information. If you have a suspected side effect or problem to report regarding one of our products, please email <a className="App-link" href="mailto:mississauga.canada_medinfo@roche.com" rel="noopener noreferrer">mississauga.canada_medinfo@roche.com</a> or call Canada Roche Drug Safety at 1-888-762-4388. You can also report adverse events relating to any product to Health Canada directly by going to the Canada Vigilance Program website at <a className="App-link" href="https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html" rel="noopener noreferrer">https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html</a></p>
           </div>


          </Parallax>
        </div>
      </GridItem>

    </Grid>

    </div>
  );
}


export default App;